import Request, { instance, instanceMock } from "./requestUtil";

class RequestBuilder {
  self;

  constructor() {
    this.self = {
      baseUrl: "",
      headers: {},
    };
    this.detectBaseUrlBaseOnEnv();
  }

  detectBaseUrlBaseOnEnv() {
    switch (process.env.REACT_APP_ENVIRONMENT) {
      case "local":
        return this;
      case "development":
        this.self.baseUrl = `${process.env.REACT_APP_BASE_URL}`;
        return this;
      case "production":
        this.self.baseUrl = `${process.env.REACT_APP_BASE_URL}`;
        return this;
      default:
        return this;
    }
  }

  forBaseUrl(baseUrl) {
    this.self.baseUrl = process.env.REACT_APP_BASE_API_URL || baseUrl || "";
    return this;
  }

  withHeaders(headers) {
    this.self.headers = { ...this.self.headers, ...headers };
    return this;
  }

  build(option) {
    return new Request(
      option && option.mock ? "" : this.self.baseUrl,
      this.self.headers,
      option && option.mock ? instanceMock : instance
    );
  }
}

const request = new RequestBuilder();

export default request;
