import request from "../helpers/request";

function fetchReasons(params) {
  return request
    .withHeaders({ "Content-Type": "application/json;utf-8" })
    .build({ mock: true })
    .get(`/api/v1/reasons`, params);
}

function fetchBar(params) {
  return request
    .withHeaders({ "Content-Type": "application/json;utf-8" })
    .build({ mock: true })
    .get(`/api/v1/bar`, params);
}

function fetchJibeAgents(params) {
  return request
    .withHeaders({ "Content-Type": "application/json;utf-8" })
    .build()
    .get(`/api/CalibrationV3/fully-jibed-agents-stats`, params);
}

function fetchJibeSessions(params) {
  return request
    .withHeaders({ "Content-Type": "application/json;utf-8" })
    .build()
    .get(`/api/CalibrationV3/Jibe-sessions-past-30-days`, params);
}

function fetchChannels(params) {
  return request
    .withHeaders({ "Content-Type": "application/json;utf-8" })
    .build()
    .get(`/api/dataV3/channels`, params);
}

function fetchReasonsForContact(params) {
  return request
    .withHeaders({ "Content-Type": "application/json;utf-8" })
    .build()
    .get(`/api/dataV3/reasons-for-contact`, params);
}

function fetchTeams(params) {
  return request
    .withHeaders({ "Content-Type": "application/json;utf-8" })
    .build()
    .get(`/api/adminV3/teams-summary`, params);
}

function fetchUsers(params) {
  return request
    .withHeaders({ "Content-Type": "application/json;utf-8" })
    .build()
    .get(`/api/adminV3/users-summary`, params);
}

function fetchSurveyQuestions(params) {
  return request
    .withHeaders({ "Content-Type": "application/json;utf-8" })
    .build()
    .get(`/api/dataV3/calibratedOn-surveyquestions`, params);
}


function fetchSurveyActivity(params) {
  return request
    .withHeaders({ "Content-Type": "application/json;utf-8" })
    .build()
    .get(`/api/SurveyActivity/survey-count`, params);
}

function fetchSurveyQA(params) {
  return request
    .withHeaders({ "Content-Type": "application/json;utf-8" })
    .build()
    .get(`/api/QaDashboard/qa-survey-count-for-all-surveyforms`, params);
}

function fetchOverallPerformance(params) {
  return request
    .withHeaders({ "Content-Type": "application/json;utf-8" })
    .build()
    .get(`/api/systemdashboardv3/overall-performance-progress-over-time-stats`, params);
}

function fetchAHTOverallPerformance(params) {
  return request
    .withHeaders({ "Content-Type": "application/json;utf-8" })
    .build()
    .get(`/api/systemdashboardv3/handle-time-over-time-stats`, params);
}


const servicesServices = {
  fetchReasons,
  fetchBar,
  fetchJibeSessions,
  fetchJibeAgents,
  fetchChannels,
  fetchReasonsForContact,
  fetchTeams,
  fetchUsers,
  fetchSurveyQuestions,
  fetchSurveyActivity,
  fetchSurveyQA,
  fetchOverallPerformance,
  fetchAHTOverallPerformance
};

export default servicesServices;
